import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";

import PortableText from "../components/portable-text";

export const query = graphql`
  query($slug: String!) {
    sanityProject(slug: { current: { eq: $slug } }) {
      id
      title
      mainImage {
        alt
        asset {
          fluid(maxWidth: 750) {
            ...GatsbySanityImageFluid
          }
        }
        crop {
          top
          right
          left
          bottom
          _type
          _key
        }
        hotspot {
          _key
          _type
          height
          width
          y
          x
        }
      }
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      images {
        _key
        alt
        asset {
          fluid(maxWidth: 750) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  const mainImage = data.sanityProject.mainImage;
  const images = data.sanityProject.images || [];

  return (
    <Layout>
      <SEO title={data.sanityProject.title} />
      <section className="px-8 max-w-screen-xl p-4 mx-auto md:px-8">
        <div className="flex flex-col md:flex-row py-6">
          <div className="md:w-1/3 flex flex-col justify-center">
            {mainImage && mainImage.asset && (
              <a
                href={mainImage.asset.fluid.src}
                target="_blank"
                rel="noreferrer"
                className="h-full bg-gray-200 overflow-hidden group"
                style={{ maxHeight: "48rem" }}
              >
                <Img
                  style={{ height: "100%" }}
                  className=" w-full object-cover group-hover:opacity-75 transition duration-300 "
                  fluid={mainImage.asset.fluid}
                  alt={mainImage.alt}
                />
              </a>
            )}
          </div>

          <div className="md:w-2/3 md:pl-16 flex flex-col justify-center">
            <h1 className="uppercase font-bold text-4xl text-blue-branding text-center md:text-left">
              {data.sanityProject.title}
            </h1>

            {data.sanityProject._rawBody && (
              <>
                <h2 className="uppercase font-medium text-xl text-blue-branding pb-4">
                  Project omschrijving
                </h2>
                <PortableText
                  blocks={data.sanityProject._rawBody}
                  className="text-gray-700 "
                />
              </>
            )}

            {images && images.length > 0 && (
              <div className="flex flex-wrap mb-2">
                {images.map((image) => (
                  <a
                    href={image.asset.fluid.src}
                    target="_blank"
                    rel="noreferrer"
                    key={image._key}
                    className="w-full md:w-20 flex-auto first:px-0 md:px-1 py-2 md:py-6 group"
                  >
                    <Img
                      style={{ height: "100%" }}
                      className=" w-full object-cover group-hover:opacity-75 transition duration-300 bg-gray-200"
                      fluid={image.asset.fluid}
                      alt={image.alt}
                    />
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

Project.propTypes = {
  data: PropTypes.object,
};

export default Project;
